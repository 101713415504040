<script>
import axios from "axios";

export default {
  name: "ViewProfileIframe",
  data() {
    return {
      customer: {},
      is_loading: false,
      token: {
        status: 'loading',
        value: null
      }
    }
  },
  methods: {
    async getCustomer() {
      try {
        let response = await axios.get(`customer/companies/${this.$route.params.customer_slug}/`)
        this.customer = response.data || {}
        await this.getToken(this.customer.id)
      } catch {
        this.customer = {}
      }
    },
    async getToken(customer_id) {
      this.token.status = 'loading'
      try {
        let response = await axios.post(`/user/admin/access-customer/`, {
          params: {
            customer_id: customer_id
          }
        })
        this.token.value = response.data.access
        this.token.status = 'success'
      } catch {
        this.token.status = 'error'
      }
    }
  },
  watch: {
    "$route.params.customer_slug": {
      handler: function (val) {
        if (val) this.getCustomer()
      },
      deep: true
    }
  },
  mounted() {
    this.getCustomer()
  }
}
</script>

<template>
  <b-card no-body v-if="token">
    <b-card-body>
      <b-card-title class="d-flex gap-2">
        <VTooltip>
          <i :class="{
          'mdi mdi-circle text-success': token.status === 'success',
          'mdi mdi-circle text-danger': token.status === 'error',
          'mdi mdi-loading mdi-spin text-warning': token.status === 'loading',
            }"></i>
          <template v-slot:popper>
            <span v-if="token.status === 'success'">Access Granted</span>
            <span v-if="token.status === 'error'">Access Denied</span>
            <span v-if="token.status === 'loading'">Access Pending</span>

          </template>
        </VTooltip>
        {{ customer.name }}
        <a :href="`https://my.interrail.uz/sign-in/?token=${token.value}`">
          <i class="mdi mdi-link"></i>
        </a>
      </b-card-title>
      <iframe :src="`https://my.interrail.uz/sign-in/?token=${token.value}`"
              width="100%" height="1000vh"></iframe>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>